<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code >
                <validation-observer ref="simpleRules">
                    <form @submit.prevent="submitForm">
                      <b-row>
                        <!-- <b-col md="6">
                          <b-form-group>
                            <label>Supplier Name</label>
                            <v-select v-model="suppliername" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="None" :options="supplieroption" />
                          </b-form-group>
                        </b-col> -->
                        <b-col md="6">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr class="form-control"
                            value=""
                            placeholder="Select Date"
                            :config="{
                            dateFormat: 'd/m/Y',
                            mode: 'range'}"
                            style="background-color: transparent"
                            @input="getdata($event)"/>
                        </b-form-group>
                      </b-col>
                        <!-- <b-col md="6">
                          <b-form-group>
                            <label>Select Phase</label>
                            <v-select v-model="selectphase" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="None" :options="phaseoption" />
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <label>Company Name</label>
                            <v-select v-model="companyname" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="None" :options="companyoption" />
                          </b-form-group>
                        </b-col> -->
                        </b-row>
                      <div class="d-flex justify-content-end">
                        <b-button  variant="primary" class="ml-2" @click="searchData">
                          <feather-icon icon="SearchIcon" class="mr-50" />
                          <span class="align-middle">Search</span>
                        </b-button>
                      </div>
                    </form>
                </validation-observer>
            </b-card-code>
              <report-table
                :data="data"
                :columns="fields"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from '../../../components/ReportTable.vue'
import axios from '@/components/axios'
import Table from '@/components/Table.vue'
import BCardCode from '@core/components/b-card-code'
import flatPickr from 'vue-flatpickr-component'
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Form from '@/components/form/Form.vue'
import moment from 'moment'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy
} from 'bootstrap-vue'
const baseApi = process.env.VUE_APP_APIENDPOINT
import vSelect from 'vue-select'
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch
  },
  data () {
    return {
      suppliername: '',
      supplieroption: [],
      startdate: '',
      enddate: '',
      result: '',
      selectphase: '',
      phaseoption: [],
      companyname: '',
      companyoption: [],
      data: '',
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken:localStorage.getItem('accessToken'),
      fields: [
        { field: 'id', label: 'ID', hidden: true,  },
        { field: 'clientname', label: 'Supplier Name', url: 'ledger', type:'dropdown', responseValue:'name', },
        { field: 'date', label: 'Date', },
        { field: 'selectphase', label: 'Select Phase', url: 'phase', type:'dropdown', responseValue:'name', },
        { field: 'companyname', label: 'Company Name', url: 'company', type:'dropdown', responseValue:'name',  },
        { field: 'naexp', label: 'N.A Exp',},
        { field: 'developmentexp', label: 'Development Exp',  },
        { field: 'stampdutyexp', label: 'Stamp Duty Registration Exp', },
        { field: 'premiumregexp', label: 'Premium Registration Exp',  },
        { field: 'planpassfee', label: 'Plan Passing/FSI - Fees',  },
        { field: 'otherlegalcost', label: 'Other Legal Cost',  },
        { field: 'deedno', label: 'Deed No', },
        { field: 'newsurveyno', label: 'New Survey No', },
        { field: 'oldsuveyno', label: 'Old Survey No',  },
        { field: 'subdistrict', label: 'Sub District',  },
        { field: 'projectname (Project Master API)', label: 'Project Name (Project Master API)', },
        { field: 'areasqmts', label: 'Area SQ.MTS',  },
        { field: 'areasqyards', label: 'Area SQ.YARDS', },
        { field: 'rate', label: 'Rate', },
        { field: 'total', label: 'Total Amount',}

      ]
    }
  },
  mounted () {
    this.getSupplier()
    this.getPhase()
    this.getCompany()
  },
  methods: {
    getdata (selectedDates) {
      this.result = selectedDates.split(' to ')
      this.startdate = this.result[0]
      this.enddate = this.result[1]
    },
    async searchData () {
      const data = {
        suppliername:this.suppliername.clientname,
        startdate:this.startdate,
        enddate:this.enddate,
        selectphase:this.selectphase.id,
        companyname:this.companyname.id
      }
      await axios({
        method:'POST',
        headers:{
          'Content-Type':'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        data:JSON.stringify(data),
        url:`${this.baseApi}/landpurchasereport`
      })
        .then((response) => {
          this.data = response.data.data
        })
    },
    async getSupplier () {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        url: `${this.baseApi}/landpurchase`
      }
      await axios(requestOptions)
        .then((response) => {
          this.supplieroption = response.data.data.data
          console.log(this.supplieroption,'supplieroption')
        })
        .catch((error) => console.log(error, 'error'))
    },
    async getPhase () {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        url: `${this.baseApi}/phase`
      }
      await axios(requestOptions)
        .then((response) => {
          this.phaseoption = response.data.data.data
        })
        .catch((error) => console.log(error, 'error'))
    },
    async getCompany () {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.accessToken}`
        },
        url: `${this.baseApi}/company`
      }
      await axios(requestOptions)
        .then((response) => {
          this.companyoption = response.data.data.data
        })
        .catch((error) => console.log(error, 'error'))
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

